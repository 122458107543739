@charset "utf-8";

.slider-pro.center {
  overflow: hidden;
  width: 100%;

  // buttons
  .sp-button {
    border: none;
    background: $color_slider_button;
  }
  .sp-selected-button {
    background: $color_slider_button_active;
  }

  // arrows
  &.sp-horizontal .sp-arrows {
    left: 0;
    right: 0;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  &.sp-horizontal .sp-previous-arrow {
    left: 10px;
  }
  &.sp-horizontal .sp-next-arrow {
    right: 10px;
  }
  .sp-previous-arrow,
  .sp-next-arrow {
    width: 30px;
    height: 30px;
    @if $shape_slider_arrow == 'circle' {
      border-radius: 50%;
    }
    background-color: $color_sliderA_arrow;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 8px;
  }
  .sp-previous-arrow {
    background-image: url(/wp-content/uploads/arrow_left_wh.png);
  }
  .sp-next-arrow {
    background-image: url(/wp-content/uploads/arrow_right_wh.png);
  }
  .sp-next-arrow:after,
  .sp-next-arrow:before,
  .sp-previous-arrow:after,
  .sp-previous-arrow:before {
    display: none;
  }

  // キャプション(caption)
  .sp-layer {
    line-height: 1.2;
    white-space: normal !important;
    @include fontsize(20);
    color: $color_slider_layer;
    small {
      @include fontsize(14);
    }
  }
}


@media ( min-width : 768px ) {

.slider-pro.center {

  // arrows
  .sp-arrows {
    @if $shape_slider_arrow == 'circle' {
      margin-top: -30px;
    }
  }
  .sp-previous-arrow,
  .sp-next-arrow {
    @if $shape_slider_arrow == 'circle' {
      width: 60px;
      height: 60px;
      background-size: auto;
    }
  }

  // キャプション(caption)
  .sp-layer {
    @include fontsize(22);
  }
  .sp-padding {
    padding: 35px;
  }

}

}


@media ( min-width : 1100px ) {

.slider-pro.center {
  // arrows
  &.sp-horizontal .sp-previous-arrow {
    @if $shape_slider_arrow == 'square' {
      left: -15px;
    } @else if $shape_slider_arrow == 'circle' {
      left: -30px;
    }
  }
  &.sp-horizontal .sp-next-arrow {
    @if $shape_slider_arrow == 'square' {
      right: -15px;
    } @else if $shape_slider_arrow == 'circle' {
      right: -30px;
    }
  }
}

}
