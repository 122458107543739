@charset "utf-8";

// ======== key color
  $color_primary: #083388; // 基本カラー(màu cơ bản)

// ======== background color
  $color_body_bg: #fff; // body背景(nền body)
  $color_highlight_bg: rgba($color_primary, .05); // 帯の背景(nền của highlight)
  $color_inquiry_bg: $color_highlight_bg; // お問い合わせフォーム背景(màu form お問い合わせ)

// ======== text color
  $color_text: #1b1b1b; // 基本文字色(màu text cơ bản)
  $color_link: $color_text; // リンクカラー(màu link)
  $color_link_hover: $color_link; // リンクカラー（マウスホバー）(màu link(mouse hover)

  $color_h1_text: $color_text; // 下層h1テキスト色(màu text h1 sub page)
  $color_h2_text: $color_text; // h2テキスト色(màu text h2)
  $color_h2_border: $color_h2_text; // h2ボーダー色(màu border h2)
  $color_h3_text: $color_text; // h3テキスト色(màu text h3)
  $color_h4_text: $color_text; // h4テキスト色(màu text h4)

  // 帯のテキスト色(mau text của highlight)
  $color_highlight_text: $color_text; // 帯の文字色(màu text highlight)
  $color_highlight_link: $color_text; // 帯のリンクカラー(màu link của highlight)
  $color_highlight_link_hover: $color_link_hover; // 帯のリンクカラー（マウスホバー）(màu link của highlight(mouse hover)

  $color_highlight_h2_text: $color_h2_text; // 帯のh2テキスト色(màu text h2 của highlight)
  $color_highlight_h2_border: $color_h2_border; // 帯のh2ボーダー色(màu border h2 của highlight)
  $color_highlight_h3_text: $color_h3_text; // 帯のh3テキスト色(màu text h3 của highlight)

  // 固定文字色(màu text cố định)
  $color_white: #fff;
  $color_black: #000;
  $color_red: #f00;

// ======== header

  // 共通(chung)
  $header_border_width: 0px; // ヘッダーのボーダー幅(chiều rộng border của header)
  $header_border_position: top; // ヘッダーのボーダー位置(vị trí border của header)
  $header_border_color: $color_primary; // ヘッダーのボーダー色(màu border của header)

  // メインヘッダー(main header)
    $color_header_bg: $color_body_bg; // ヘッダー背景色(màu nền header)
    $color_header_text: $color_text; // ヘッダー文字色(màu text header)

    $color_hamburger: $color_primary; // SPメニューボタンの色(màu của border menu SP)
    $color_navlink_pc: $color_text; // PC Gナビリンクカラー(màu link G nabi PC)
    $color_navlink_pc_hover: $color_primary; // PC Gナビリンクカラー(ホバー)(màu link G nabi PC(hover))

    $color_gnav_bg: rgba($color_primary, .9); // SP Gナビ背景色(màu nền G navi SP)
    $color_gnav_border_sp: #333; // SP Gナビボーダーカラー(màu border G navi SP)
    $color_navlink_sp: #fff; // SP Gナビリンクカラー(màu link G navi SP)
    $color_navlink_sp_active: #fff; // SP Gナビリンクカラー（アクティブ）(màu link G navi SP(active))
    $color_navlink_sp_active_bg: $color_primary; // SP Gナビリンク背景色（アクティブ）(màu nền link G navi SP(active))

  // サブヘッダー(sub header)
    $color_subheader_bg: $color_highlight_bg; // 背景色(màu nền)
    $color_subheader_text: $color_text; // 文字色(màu text)

    // ローカルナビ(global navi)
    $color_localnavlink: $color_text; // リンク色(màu link)
    $color_localnavlink_hover: $color_primary; // リンク色(ホバー)(màu link(hover))

// ======== footer
  $color_footer_bg: $color_primary; // フッター背景色(màu nền footer)
  $color_footer_text: #fff; // フッターテキスト色(màu text footer)

  $color_ftnavlink: $color_footer_text; // フッターリンク色(màu link footer)
  $color_ftnavlink_hover: $color_ftnavlink; // フッターリンク色（ホバー）(màu link footer(hover))
  $color_ftnav_border_sp: #666; // フッターリンク間のボーダー(SP)(button giữa footer link(SP))

  $color_pagetop_bg: rgba($color_primary, .9); // トップに戻るボタンの背景色(màu nền button trở về top)
  $color_pagetop_arrow: #fff; // トップに戻るボタンの矢印の色(màu mũi tên button trở về top)

// ======== table
  $color_table_border: $color_primary; // テーブルのボーダー色(màu border của table)
  $color_table_th_bg: rgba($color_table_border, .7); // thの背景色(màu nền của th)
  $color_table_th_text: #fff; // thの背景色(màu nền của th)
  $color_table_td_bg: transparent; // tdの背景色(màu nền của td)

  // メニュー表(bảng menu)
  $color_menutable_border: $color_primary; // メニュー表のボーダー色(màu border của bảng menu)

  // 診療時間
  $color_schedule_border: $color_primary; // ボーダー色(màu border)
  $color_schedule_thead_bg: rgba($color_table_border, .7); // thead > th の背景色(màu nền thead > th )
  $color_schedule_thead_text: #fff; // thの背景色(màu nền th)
  $color_schedule_tbody_th_bg: transparent; // thead > th の背景色(màu nền thead > th )
  $color_schedule_td_bg: transparent; // td の背景色(màu nền của td)

  // effect
  $effect_arrow_color: $color_primary;
  $effect_more_color: $color_primary;
  $effect_more_color: $color_primary;

// ======== button

  $style_button_border_radius: 4px; // 角丸(góc tròn)

  // デフォルトボタン(defaut button)
  $color_button_bg: $color_primary; // 背景色(màu nền)
  $color_button_text: #fff; // テキスト色(màu text)
  $color_button_bg_hover: darken(#e9eceb, 10%); // ホバー時背景色(màu nền khi hover)
  $color_button_text_hover: $color_primary; // ホバー時テキスト色(màu text khi hover)

  // カラー反転ボタン(button phản ánh màu sắc)
  $color_button_inverse_bg: #000; // 背景色(màu nền)
  $color_button_inverse_text: #fff; // テキスト色(màu text)
  $color_button_inverse_bg_hover: lighten($color_button_inverse_bg, 10%); // ホバー時背景色(màu nền khi hover)
  $color_button_inverse_text_hover: $color_button_inverse_text; // ホバー時テキスト色(màu text khi hover)

  // キャンセルボタン(button cancel)
  $color_button_disable_bg: #888; // 背景色(màu nền)
  $color_button_disable_text: #fff; // テキスト色(màu text)
  $color_button_disable_bg_hover: darken($color_button_disable_bg, 10%); // ホバー時背景色(màu nền khi hover)

// ======== slider
  $slide_type: 'A'; // スライドのタイプ(type của slide)
  $color_slider_button: #d2d2d2; // ページャーの色(màu của pager)
  $color_slider_button_active: $color_primary; // ページャーの色（アクティブ）(màu của pager(active)
  $color_slider_layer: $color_text; // キャプションの文字色(màu text của caption)
  $color_slider_shadow: #fff; // キャプションのシャドー色(màu shadow của caption)

  // Type A
  $shape_slider_arrow: 'square'; // 前後切り替えボタンの形状 'square' or 'circle'(hình dạng button trước sau thay đổi 'square' or 'circle')
  $color_sliderA_arrow: rgba($color_primary, 1 - 30/100); // 前後切り替えボタンの背景色(màu nền củ button trước sau thay đổi)

  // Type B
  $color_slider_mask_bg: rgba(#fff, 0.2); // マスクの背景色(màu nền của mask)
  $color_sliderB_arrow: #fff; // 前後切り替えボタンの矢印の色(màu của mũi tên button trước sau thay đổi)

  // コンテンツスライダー(contents slider)
  $color_contents_slider_arrow: $color_primary; // 前後切り替えボタンの色(màu button thay đổi trước sau)

// ======== information
  $color_news_header: $color_h2_text; // お知らせのh2テキスト色(màu text h2 của お知らせ)
  $color_news_bg: $color_highlight_bg; // お知らせの背景色(màu nền của  お知らせ)

// ======== step
  $color_step_bg: $color_highlight_bg; // STEPパーツの背景色(màu nền của STEP parts)

// ======== Q&A
  $color_qa_bg: $color_highlight_bg; // Q&Aパーツの背景色(màu nền của Q&A parts)
