@charset "utf-8";

/*
 * ボタン矢印(mũi tên button)
 */
.add_arrow {
  display: block;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 10%;
    margin: auto;
    width: 6px;
    height: 6px;
    border-top: 1px solid $effect_arrow_color;
    border-right: 1px solid $effect_arrow_color;
    transform: translate(0, -50%) rotate(45deg);
    transition: .2s;
  }
  &:hover {
    &:after {
      right: 7%;
    }
  }
}

/*
 * moreボタン雛形 (hình dạng button more)
 */
.more {
  display: inline-block;
  overflow: hidden;
  margin: auto;
  a {
    display: inline-block;
    letter-spacing: normal;
    border: 1px solid $effect_more_color;
    color: $effect_more_color;
    padding: .3em 1.3em .3em 1em;
    margin-bottom: 1px;
    text-decoration: none;
    @include fontsize(14);
    @media ( min-width : 768px ) {
      @include fontsize(18);
    }
  }
}

/*
 * ボタンエフェクト(button effect)
 */
@media ( min-width : 768px ) {
  .effect01 {
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 140%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: -1;
      background: $effect_more_color;
      opacity: .3;
      transform: translateX(-120%) skewX(15deg);
      transition: transform .3s;
    }
    &:hover {
      @if $design_concept == 'material' {
        box-shadow: 0 0 30px 0 rgba(0,0,0,.3);
      }
      &:before {
        transform: translateX(-15%) skewX(15deg);
      }
    }
  }
}
