@charset "utf-8";

// パンクズ(breadcrumb list)
main section {
  .bread_wrap {
    background: transparent;
    color: $color_primary;
    margin-bottom: 2%;
  }
}
.bread {
  overflow: hidden;
  margin: 0;
  @include fontsize(12);
 
  ul {
    padding: 1em;
    margin: 0 auto;
    float: none;
    li {
      list-style: none;
      position: relative;
      float: left;
      padding-right: 2em;
      margin-right: .5em;
      a {
        text-decoration: none;
        color: $color_link;
        &:hover {
          color: $color_link_hover;
          text-decoration: none;
        }
      }
      &:after {
        content: '>';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      &:last-child:after {
        content: none;
      }
    }
  }
}
