@charset "utf-8";

header {
  .global_nav {
    >ul {
      >li {
        // 2階層目(sub page thứ 2)
        &.has_under {
          >a {
            position: relative;
            &:before,
            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              margin: auto;
              width: 15px;
              height: 2px;
              background: #fff;
              transition: .2s;
            }
            &:before {
              transform: translateY(-50%) rotate(-45deg);
              right: calc(5% - 5px);
            }
            &:after {
              transform: translateY(-50%) rotate(45deg);
              right: calc(5% + 5px);
            }
            &.menu_under_open {
              &:before {
                transform: translateY(-50%) rotate(45deg);
              }
              &:after {
                transform: translateY(-50%) rotate(-45deg);
              }
            }
          }
        }
        >ul {
          @include listclear();
          display: none;
          >li {
            border-bottom: 1px solid $color_gnav_border_sp;
          }
        }
      }
    }
  }
}

@media ( min-width : $header_break_point+px ) {

  header {
    .global_nav {
      >ul {
        @if $header_menu_type == 'B' {
          position: relative;
          top: auto;
          z-index: 1;
        }
        >li {
          &.has_under {
            @if $header_menu_type == 'C' {
              position: relative;
            }
            >a {
              padding-right: 1em;
              &:before,
              &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                margin: auto;
                width: 6px;
                height: 1px;
                background: $color_primary;
                transition: .2s;
              }
              &:before {
                transform: translateY(-50%) rotate(-45deg);
                right: calc(5% - 2px);
              }
              &:after {
                transform: translateY(-50%) rotate(45deg);
                right: calc(5% + 2px);
              }
              &.menu_under_open {
                &:before {
                  transform: translateY(-50%) rotate(45deg);
                }
                &:after {
                  transform: translateY(-50%) rotate(-45deg);
                }
              }
            }
            >ul {
              position: absolute;
              width: 100%;
              left: 0;
              right: 0;
              margin: auto;
              top: 100%;
              letter-spacing: -.40em;
              background: $color_gnav_bg;
              z-index: 52;
              >li {
                letter-spacing: normal;
                display: inline-block;
                vertical-align: middle;
                width: 25%;
                @if $header_menu_type == 'C' {
                  width: 100%;
                }
                text-align: center;
                border-bottom: 0;
                padding: 0;
                a {
                  background: transparent;
                  padding: 1em;
                }
              }
            }
          }
        }
      }
    }
  }
}


@if $header_type == 'bottom' {

  @media (min-width: $header_break_point+px) {
    body.home {
      header {
        &.low {
          .global_nav > ul > li.has_under {
            >a {
              &:before {
                transform: translateY(-50%) rotate(45deg);
              }
              &:after {
                transform: translateY(-50%) rotate(-45deg);
              }
              &.menu_under_open {
                &:before {
                  transform: translateY(-50%) rotate(-45deg);
                }
                &:after {
                  transform: translateY(-50%) rotate(45deg);
                }
              }
            }
            > ul {
              top: auto;
              bottom: 100%;
            }
          }
        }
        &.high {
          .global_nav > ul > li.has_under {
            >a {
              &:before {
                transform: translateY(-50%) rotate(-45deg);
              }
              &:after {
                transform: translateY(-50%) rotate(45deg);
              }
              &.menu_under_open {
                &:before {
                  transform: translateY(-50%) rotate(45deg);
                }
                &:after {
                  transform: translateY(-50%) rotate(-45deg);
                }
              }
            }
            > ul {
              top: 100%;
              bottom: auto;
            }
          }
        }
      }
    }
  }
}
